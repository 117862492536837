import { useContext, useEffect, useState } from "react";
import { SerialServiceContext } from ".";

function App() {
  const serialService = useContext(SerialServiceContext);

  const [isConnected, setIsConnected] = useState(false);
  const [commands, setCommands] = useState<string[]>([]);
  const [configuredList, setConfiguredList] = useState<string[]>([]);
  const [parableList, setParableList] = useState<string[]>([]);

  function oncommand(text: string) {
    setCommands((command) => [...command, text]);
  }

  useEffect(() => {
    serialService.commandState = oncommand;
  }, []);

  const isChrome = "chrome" in window;

  if (!isChrome) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen m-10">
        <h1 className="text-3xl">Unsupported Browser</h1>
      </div>
    );
  }

  const getConfiguredPanels = async () => {
    console.log("fetching...");
    await serialService.write("getallpanels\r\n");
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen m-10">
      <div className="flex flex-row gap-4">
        <h1 className="text-3xl">
          Start Config [{isConnected ? "Connected" : "Not Connected"}]
        </h1>
        <button
          className="px-5 py-2 bg-slate-400 text-white font-bold rounded-lg"
          onClick={async () => {
            if (isConnected === true) {
              await serialService.disconnect();
              setIsConnected(false);
            } else {
              serialService.connect(
                (status) => {
                  setIsConnected(status);
                  setConfiguredList([]);
                  setParableList([]);
                  getConfiguredPanels();
                },
                (text) => {
                  const [action, data] = text.split(":");

                  const rowAction = action.trim();

                  console.log("action:", rowAction, "data:", data);

                  const rowData = data.replaceAll("<", "").replaceAll(">", "");

                  if (rowAction === "SL") {
                    if (rowData.trim().length === 0) {
                      setParableList([]);
                      return;
                    }

                    const list = rowData
                      .split(",")
                      .map((panel) => panel.trim());

                    setParableList(list);
                  } else if (rowAction === "PR") {
                    if (rowData.trim().length === 0) {
                      setConfiguredList([]);
                      return;
                    }

                    const list = rowData
                      .split(",")
                      .map((panel) => panel.trim());

                    setConfiguredList(list);
                  } else if (rowAction === "PROK") {
                    getConfiguredPanels();
                  } else if (rowAction === "RMOK") {
                    getConfiguredPanels();
                  }
                }
              );
            }
          }}
        >
          {isConnected ? "Disconnect" : "Connect"}
        </button>
      </div>

      <BodyComponent
        configuredList={configuredList}
        getConfiguredPanels={getConfiguredPanels}
        parableList={parableList}
        isConnected={isConnected}
        commands={commands}
      />
    </div>
  );
}

function BodyComponent({
  getConfiguredPanels,
  configuredList,
  parableList,
  isConnected,
  commands,
}: {
  getConfiguredPanels: () => void;
  configuredList: string[];
  parableList: string[];
  commands: string[];
  isConnected: boolean;
}) {
  const serialService = useContext(SerialServiceContext);
  const [isScanning, setIsScanning] = useState(false);

  if (!isConnected) {
    return <div />;
  }

  return (
    <div className="flex items-start w-screen p-10">
      <div className="w-1/2">
        <div className="flex flex-row gap-2">
          <div className="font-semibold text-xl">Connected Panels</div>
          <button
            onClick={() => {
              getConfiguredPanels();
            }}
          >
            Refresh
          </button>
        </div>
        {configuredList.map((panel) => {
          return (
            <div className="flex flex-row gap-6 items-center my-2" key={panel}>
              <div>{panel}</div>
              <button
                className="px-2 py-1 bg-red-500 text-white font-medium rounded-lg text-xs"
                onClick={async () => {
                  await serialService.write(`rm:<${panel}>`);
                }}
              >
                REMOVE
              </button>
            </div>
          );
        })}
      </div>
      <div className="w-1/2">
        <button
          className="px-4 py-2 bg-blue-500 text-white font-bold rounded-lg"
          onClick={async () => {
            if (isScanning) {
              serialService.write("searchoff");
            } else {
              serialService.write("searchon");
            }

            setIsScanning(!isScanning);
          }}
        >
          {isScanning ? "Stop Scanning" : "Start Scanning"}
        </button>
        {parableList.map((panel) => {
          if (panel.trim().length === 0) {
            return <div />;
          }

          return (
            <div className="flex flex-row gap-6 items-center my-2" key={panel}>
              <div>{panel}</div>
              <button
                className="px-2 py-1 bg-blue-500 text-white font-medium rounded-lg text-xs"
                onClick={async () => {
                  await serialService.write(`pair:<${panel}>`);

                  // await serialService.writeRead(`add_${panel}`, kBaudRate);
                  // setConfiguredList((list) => {
                  //   return [...list, panel];
                  // });
                  // setParableList((list) => {
                  //   return list.filter((item) => item !== panel);
                  // });
                }}
              >
                Pair
              </button>
            </div>
          );
        })}
      </div>
      <div className="w-1/2 p-5">
        <div className="font-bold">Commands:</div>
        {commands.map((command, index) => (
          <div key={index}>{command}</div>
        ))}
      </div>
    </div>
  );
}

export default App;
